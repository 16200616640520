.MuiDataGrid-cell:focus {
  outline: solid #000000 4px;
}
.form-control:disabled {
  background-color: #f9f9f9;
  opacity: 1;
}
.form-select:disabled {
  background-color: #f9f9f9;
}
.text-gray,
.card .card-subtitle {
  /* color: #8c8c8c; */
  color: #5a5a5a;
}
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.defaultEditClass {
  background-color: #f9f9f9;
}
select.form-control {
  color: black;
}
.marker-cluster-small {
  // background-color: rgba(218, 94, 94, 0.6);
  color: white;
}
.marker-cluster-small div {
  // background-color: rgba(226, 36, 36, 0.6);
  color: white;
}
.marker-cluster-medium {
  // background-color: rgba(241, 211, 87, 0.6);
  color: white;
}
.marker-cluster-medium div {
  // background-color: rgba(240, 194, 12, 0.6);
  color: white;
}

.marker-cluster-large {
  // background-color: rgba(253, 156, 115, 0.6);
  color: white;
}
.marker-cluster-large div {
  // background-color: rgba(241, 128, 23, 0.6);
  color: white;
}
.checkcss {
  color: "red";
  background-color: yellow;
  font-size: 30px;
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  background-color: #e9ecef !important;
}
.css-z8fhq1-MuiDataGrid-columnHeaders {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #e9ecef !important;
  font-size: medium;
  // background-color: #f0f1f6;
}

.MuiTable-root thead th {
  text-transform: uppercase;
  padding: 10px;
  font-size: 10px;
  text-align: left;
  background: #f0f1f6;
  font-weight: 800;
  text-overflow: ellipsis;
  overflow: hidden;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller {
  position: relative;
  min-width: 120%;
  // width: 1530px !important;
  overflow: scroll;
  margin-top: 56px !important;
  // float:left
}
.css-gl260s-MuiDataGrid-columnHeadersInner {
  font-size: medium;
}

.Mui-resizeTriggers {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 120% !important;
  overflow: hidden;
  z-index: -1;
}

.table_icon {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  text-rendering: auto;
  line-height: inherit;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f59d0e;
}
.grey_bg {
  background: #e2e5e7;
}
.orangebackground {
  background-color: #f59d0e;
}
.fontclass {
  font-size: 13px !important;
}
.css-11yukd5-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0px;
  width: 98%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #f59d0e !important;
}

.box {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 15px;
  margin: 10px;
}
.box img {
  float: right;
}
.box h6 {
  color: var(--grayishBlue);
}
.red {
  border-left: 2px solid orange;
  margin: 14px 22px 0px 2px;
}

.App99 {
  margin: 0;
  padding: 0;
  // min-height: 100vh;
}

.App99,
.nav99,
.menu99 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav99 {
  position: relative;
  background-color: #fff;
  // padding: 5px;
  transition: 0.5s;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.menu99 {
  margin: 0;
  padding: 0;
  width: 0;
  overflow: hidden;
  transition: 0.5s;
}

.nav99 input:checked ~ .menu99 {
  width: 180px;
}

.menu99 li {
  list-style: none;
  margin: 0 10px;
}

.menu99 li a {
  text-decoration: none;
  color: orange;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.5s;
}

.menu99 li a:hover {
  color: red;
}

.nav99 input {
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}

.nav99 span {
  position: absolute;
  // left: 15px;
  left: 11px;
  width: 20px;
  height: 4px;
  border-radius: 50px;
  background-color: orange;
  pointer-events: none;
  transition: 0.5s;
}

.nav99 input:checked ~ span {
  background-color: #f974a1;
}

.nav99 span:nth-child(2) {
  transform: translateY(-2px) rotate(-90deg);
}

.nav99 input:checked ~ span:nth-child(2) {
  transform: translateY(0) rotate(-45deg);
}
.nav99 span:nth-child(3) {
  transform: translateY(-2px);
}

.nav99 input:checked ~ span:nth-child(3) {
  transform: translateY(0) rotate(45deg);
}

.filtersOuter {
  width: 300px;
  background: #d9d9d9;
  // margin : 40px auto;
}

.filtersHeading {
  // padding : 30px;
  padding: 8px;
  background: white;
  color: orange;
  font-size: 1.2em;
  font-variant: small-caps;
  cursor: pointer;
  display: block;
}

.filtersHeading::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.4s ease;
}

.slide li {
  padding: 10px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked + .slide {
  height: 300px;
}

.filters_container {
  position: relative;
  display: inline-block;
}
.textgrey {
  color: grey;
}
.editiconsize {
  font-size: 20px !important;
  color: orange;
}
.filters_button {
  // padding: 20px;
  // width: 50px;
  border: 1px solid transparent;
  background-color: white;
  color: orange;
  // cursor: pointer;
  // outline: 0;
  // font-size: 40px;
}
.filters_dropdown {
  position: absolute;
  top: 100%;
  left: -102px;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: white;
  padding: 20px;
}
.filters_dropdown2 {
  position: absolute;
  top: 100%;
  // left: -102px;
  width: 500px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: white;
  padding: 12px;
}
.filters_dropdownFilters {
  position: absolute;
  right: 0;
  // left: -102px;
  width: 280px !important;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: #f8f8f8;
  padding: 8px;
  margin-top: 46px;
}
.filters_ActionFilters {
  position: absolute;
  left: 0;
  // left: -102px;
  width: 280px !important;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: #f8f8f8;
  padding: 5px;
  margin-top: 4px;
}

.filters_dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.filters_dropdown ul li {
  padding: 8px 12px;
}

.filters_dropdown ul li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.coverOptions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.coverOptions li {
  list-style: none;
  margin: 0 10px;
}

.coverOptions li a {
  text-decoration: none;
  color: orange;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.5s;
}

.coverOptions li a:hover {
  color: green;
}
.checkingcss {
  color: red;
}
.buttonResponsive {
  margin-left: -59px;
  margin-top: -104px;
}
@media only screen and (max-width: 540px) {
  .buttonResponsive {
    margin-left: 163px;
    padding: 10px;
    margin-top: -150px;
  }
}
.table th {
  font-weight: 500;
  color: #5b626b;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.table td {
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.bg-secondary2 {
  background-color: #e9ecef;
}

.order-card {
  color: #fff;
}
.order-card2 {
  color: #fff;
  border-radius: 10px;
  box-shadow: orange 0px 0px 2px 0px inset, orange -3px -3px 6px 1px inset;
  cursor: pointer;
}
.bgBorderBottomClass {
  border-bottom: 2px solid #4099ff;
  border-radius: 10px;
  cursor: pointer;
}
.card {
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.MasterDataCard {
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.bgblueCard {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}
.bgGreenCard {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

//location
.bg-c-blue {
  // background: linear-gradient(45deg,#4099ff,#73b4ff);
  background-image: url("https://thumbs.dreamstime.com/b/india-marked-flag-map-137555598.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

//bank
.bg-c-green {
  // background: linear-gradient(45deg,#2ed8b6,#59e0c5);
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxtt0wQpnj4irV9wGwEirQu7X_cilDtGIzNA&usqp=CAU");
  background-size: cover;
  background-repeat: no-repeat;
}

//dropdown data
.dropdownCard {
  // background: linear-gradient(45deg,#FFB64D,#ffcb80);
  background-image: url("https://contentstatic.techgig.com/photo/77786852/Top-7-most-used-databases-by-developers-in-2020.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}
.EmployeeCard {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  /* margin-bottom: 30px; */
  transition: all 0.3s ease-in-out;
}

//crops
.bg-c-pink {
  // background: linear-gradient(45deg,#FF5370,#ff869a);
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDomxrAZzl5yGc59u9MnC82WgUbxoLg7Kbfw&usqp=CAU");
  background-size: cover;
  background-repeat: no-repeat;
}
.lightblack {
  color: rgba(0, 0, 0, 0.6);
}
.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

/* Zoom-in effect on hover */
.card-image:hover {
  height: 195px;
}

.dashCard {
  // margin-top: -12px;
  width: 185px;
}
.card-block:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.card-block {
  padding: 15px;
  border-radius: 10px;
  // height: 120px;
}
.statescard {
  padding: 29px;
  height: 150px;
  border-radius: 6px;
  box-shadow: inset 0 0 33px #b0aeaeb0, 3px 3px 5px 0 #fff0;
  cursor: pointer;
}
.statecard:hover {
  filter: blur(1px) !important;
}
.lowercard {
  border-radius: 0px 0px 5px 5px;
}
// .lowercard:hover{
//   background-color: transparent !important;
//   color: #f0f1f6;
//   transition:  background-color 1s ease;

// }
.MasterDataCard:hover {
  padding: 10px !important;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}
.addLeadbtn {
  margin-left: 41%;
}
.addApplicationbtn {
  margin-left: 17%;
}
.Searchbtn {
  margin-top: 2%;
}

.bg-orangeColor {
  color: #f59d0e;
}
.text-BlueColor {
  color: #4099ff;
}
.UserProfilrCardButon {
  border: 1px solid transparent;
  color: #4099ff;
  background-color: aliceblue;
  padding: 0.4em;
  border-radius: 7px;
}
.buttonOrangeDefaultColor {
  background-color: #f59d0e !important;
  border: 1px solid transparent;
  font-weight: 400;
}
.buttonOrangeDefaultColor:hover {
  background-color: #f8b425 !important;
  border: 1px solid transparent !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}
.buttonOrangeDefaultColor2 {
  background-color: #f8b425;
  border: 1px solid transparent !important;
  font-weight: 400;
}
.buttonOrangeDefaultColor2:hover {
  border: 1px solid transparent;
  background-color: #f59d0e !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.buttonGreenDefaultColor {
  background-color: #60a44b !important;
  border: 1px solid transparent !important;
  font-weight: 400;
}
.buttonGreenDefaultColor:hover {
  border: 1px solid transparent;
  background-color: #f59d0e !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
  color:white;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 0.975rem !important;
}
.districtimg {
  background-image: url("https://www.kashmir-tourism.org/images/head/patnitop.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//goa
.state-30-img {
  background-image: url("https://www.cleartrip.com/collections/wp-content/uploads/2018/04/Candolim-Beach-1-499x330.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
}
//telangana
.state-36-img {
  background-image: url("https://w0.peakpx.com/wallpaper/327/605/HD-wallpaper-charminar-hyderabad-place-india.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  // transform: scaleX(-1);
}
.state-9-img {
  background-image: url("https://www.fabhotels.com/blog/wp-content/uploads/2018/07/Taj-Mahal.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//jammu and kashmir
.state-1-img {
  background-image: url("https://www.kashmir-tourism.org/images/head/patnitop.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//sikkim
.state-11-img {
  background-image: url("https://www.esikkimtourism.in/wp-content/uploads/2019/04/buddhaprkoctbr.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//odisha
.state-21-img {
  background-image: url("https://m.economictimes.com/thumb/msid-70259692,width-1200,height-900,resizemode-4,imgsize-325334/orissa1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//tripura
.state-16-img {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/2/2b/Tripura_State_Museum_Agartala_Tripura_India.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

//uttrakhand
.state-5-img {
  background-image: url("https://www.holidaytravel.co/img_cache/100P418680063595a36a6b22f4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//ladakh
.state-37-img {
  background-image: url("https://discoverlehladakh.in/wp-content/uploads/2020/06/Pangong-Lake-Ladakh-1024x530.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//kerala
.state-32-img {
  background-image: url("https://img.theculturetrip.com/wp-content/uploads/2021/12/kerala-backwaters-nature-photographer-29ezcwtmtnm-unsplash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//chattisgarh
.state-22-img {
  background-image: url("https://mediaim.expedia.com/destination/1/0dabbf6a07689fabeefdbf2f2215670a.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Manipur
.state-14-img {
  background-image: url("https://i0.wp.com/www.tusktravel.com/blog/wp-content/uploads/2021/02/Sanamahi-Temple-Imphal-Manipur.jpg?resize=1080%2C810&ssl=1");
  background-size: cover;
  background-repeat: no-repeat;
}
//Meghalaya
.state-17-img {
  background-image: url("https://assets.sentinelassam.com/h-upload/2022/11/19/413724-stones-pelted-on-tourist-vehicles-in-dawki-of-meghalaya.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
//Assam
.state-18-img {
  background-image: url("https://imgcld.yatra.com/ytimages/image/upload/v1517482087/AdvNation/ANN_DES71/ann_top_Assam_c49hzx.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Andhra-pradesh
.state-28-img {
  background-image: url("https://peopleplaces.in/wp-content/uploads/2023/01/Kadapa-Andhra-pradesh.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
}
//Bihar
.state-10-img {
  background-image: url("https://static.langimg.com/thumb/95515546/historical-places-of-bihar-or-7-wonders-of-bihar-95515546.jpg?imgsize=153468&width=540&height=405&resizemode=75");
  background-size: cover;
  background-repeat: no-repeat;
}
//Gujarat
.state-24-img {
  background-image: url("https://images.moneycontrol.com/static-hindinews/2022/09/unity-770x430.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Arunachal Pradesh
.state-12-img {
  background-image: url("https://www.alfrescogrand.com/assets/resources/arunachal.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//The Dadra And Nagar Haveli And Daman And Diu
.state-38-img {
  background-image: url("https://www.tourismdddnh.in/wp-content/uploads/2019/10/Diu-Fort-e1595587360667.png");
  background-size: cover;
  background-repeat: no-repeat;
}
//Mizoram
.state-15-img {
  background-image: url("https://im.indiatimes.in/content/2022/May/aizawl-mizoram1_6290a0de1344e.jpg?w=820&h=540&cc=1");
  background-size: cover;
  background-repeat: no-repeat;
}
//Nagaland
.state-13-img {
  background-image: url("https://www.adotrip.com/public/images/state/contentImg/5f21587011502.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Haryana
.state-6-img {
  background-image: url("https://www.nativeplanet.com/img/2018/09/mahabharata-1535781677.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Rajasthan
.state-8-img {
  background-image: url("https://tds.indianeagle.com/wp-content/uploads/2022/07/Rajasthan.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

//Puducherry
.state-34-img {
  background-image: url("https://www.trawell.in/admin/images/upload/092856236Pondicherry_Auroville_Main.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Jharkhand
.state-20-img {
  background-image: url("https://static.toiimg.com/thumb/width-600,height-400,msid-92342124.cms");
  background-size: cover;
  background-repeat: no-repeat;
}
//Punjab
.state-3-img {
  background-image: url("https://www.holidify.com/images/bgImages/PUNJAB.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Maharashtra
.state-27-img {
  background-image: url("https://www.fabhotels.com/blog/wp-content/uploads/2019/10/Maharashtra-Tourism_600-1280x720.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Madhya Pradesh
.state-23-img {
  background-image: url("https://static.javatpoint.com/tourist-places/images/tourist-places-of-madhya-pradesh1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
//Lakshadweep
.state-31-img {
  background-image: url("https://www.india-a2z.com/images/agatti-island.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//Andaman And Nicobar Islands
.state-35-img {
  background-image: url("https://pyt-blogs.imgix.net/2020/05/deepak-kumar-Zwa0f934c2M-unsplash-3-scaled.jpg?auto=format&ixlib=php-3.3.0");
  background-size: cover;
  background-repeat: no-repeat;
}
//Tamil-Nadu
.state-33-img {
  background-image: url("https://t3.ftcdn.net/jpg/03/13/80/56/360_F_313805680_9TQ2uhQqAo4ZOGjGMXVlhWnr1HJ4OfsN.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

//Himachal Pradesh
.state-2-img {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
}
//west bengal
.state-19-img {
  background-image: url("https://sundayguardianlive.com/wp-content/uploads/2019/10/Howrah-Bridge.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//karnataka
.state-29-img {
  background-image: url("https://d2rdhxfof4qmbb.cloudfront.net/wp-content/uploads/20200825165508/iStock-172124032.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
//himachal-pradesh
.state-2-img {
  background-image: url("https://static.toiimg.com/thumb/msid-93736224,width-748,height-499,resizemode=4,imgsize-211314/.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.blurdistrict {
  filter: blur(1px);
}

.cardfordistrict {
  border-radius: 10px;
  // margin: 13% 8% 19% 10%;
  padding: 5%;
  height: 120px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.searchMargin {
  margin-right: 14%;
}
.searchboxtable {
  margin-top: -3.5%;
  margin-bottom: -3%;
}
.hideWrapper {
  display: none;
}
.CrmModuleNoPadding .css-19kzrtu {
  padding: 0 !important;
}
.css-19kzrtu {
  padding: 3px !important;
}
.OrangeColorClass {
  color: #f8b425;
}
.radio-inputs {
  position: relative !important;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #e9ecef;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  // padding: .3rem !important;

  padding: 0.5rem !important;
  // width: 300px;
  // font-size: 16px;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0 !important;
  // color: rgba(51, 65, 85, 1);
  color: rgb(118 124 134);
  transition: all 0.15s ease-in-out;
  // font-weight: 600;
  // font-size: 16px;
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 2px;
}

.radio-inputs .radio input:checked + .name {
  background-color: #fff;
}

.radio-inputs .radio .name:hover {
  // font-size: 15px;
  font-weight: bold;
  text-decoration: #4099ff;
  color: orange;
}
.radio-inputs .radio .name2 {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0 !important;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
  font-size: 16px !important;
}
.radio-inputs .radio input:checked + .name2 {
  background-color: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0 !important;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
  font-size: 16px !important;
}
.margin_top {
  margin-top: -1%;
}

.sign-in:hover {
  background-color: #ff6f0f !important;
}
.farmer_map {
  height: 50vh;
}

.btncolor {
  border: none;
  background-color: #f59d0e;
}

.testclass button {
  flex: 1 1 auto;
  text-align: center;
}

.userCardshadow {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.userCardshadow:hover {
  border: 1px solid orange;
}
.active_card {
  border: 1px solid orange;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.newUserParentClass {
  background-color: #e0f2ea;
}
.go169520481 {
  // justify-content: center;
  // margin-top: -42px;
  display: none;
}
.staticInputBoxClass {
  padding: 15px;
  border-radius: 0rem;

  border: 1px solid rgba(151, 151, 151, 0.3);
  color: black;
  border-radius: 5px;
}

.go169520481 {
  flex-direction: row;
  display: none;
}
.go2392553372 {
  display: none !important;
  // visibility: hidden;
}
.globalboxshadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-left: 2px solid orange;
  border-right: 2px solid orange;
}
.avatarClass {
  margin-top: -11px;
  margin-left: -9px;
  background-color: orange !important;
  /* padding: 6px; */
  font-size: 14px !important;
}
.checkTabsIcon {
  background-image: url("https://i0.wp.com/www.tusktravel.com/blog/wp-content/uploads/2021/02/Sanamahi-Temple-Imphal-Manipur.jpg?resize=1080%2C810&ssl=1");
  background-size: cover;
  background-repeat: no-repeat;
}
.croptablecss {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: revert;
}
.cardtxt {
  color: #ff9900;
}
.SelectAgentClass {
  z-index: 100000;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png)
    no-repeat;
  width: 20px;
  height: 15px;
  border-width: thin;
}
.ColouredClass {
  background-color: #f59c0d !important;
  color: white !important;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
}
.ColouredClass h5 {
  color: white !important;
}
.ColouredClass:hover {
  color: white !important;
}
.successClass {
  background-color: #44ce42 !important;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
}
.basicNavClass {
  cursor: pointer;
  font-size: 15px;
  background-color: #f5f5f5 !important;
  color: #5b626b;
  font-weight: 700;
}
.textColorHeading {
  color: #5b626b;
  font-weight: 600;
}
.f400 {
  font-size: 15px;
}
.f500 {
  font-size: 18px !important;
}
.f600 {
  font-size: 16px !important;
}
.f300 {
  font-size: 13px !important;
}
.f14 {
  font-size: 14px !important;
}
.font600 {
  font-weight: 600 !important;
}
.fontColorlightslategrey {
  color: lightslategrey;
}
.tabContentPadding {
  padding: 1rem 1rem !important;
}
.form-check-input:checked {
  background-color: orange !important;
  border-color: orange !important;
}
.oldClassWithoutModal {
  position: "absolute";
  margin-top: "15%";
  margin-left: "30rem";
}
.newClassWithoutModal {
  position: "absolute";
  margin-top: "15%";
  margin-left: "30rem";
}

.leaflet-routing-container,
.leaflet-routing-error {
  width: 250px !important;
  background-color: white;
  padding-top: 4px;
  transition: all 0.2s ease;
  box-sizing: border-box;
  height: 150px;
  overflow: hidden;
}

.bg-secondary3 {
  background-color: rgba(0, 0, 0, 0.03);
}
//table loader css
table.loader {
  width: 100%;
}

table.loader tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

table.loader tr td {
  height: 50px;
  vertical-align: middle;
  padding: 8px;
}

table.loader tr td span {
  display: block;
}

table.loader tr td.td-1 {
  width: 20px;
}

table.loader tr td.td-1 span {
  width: 20px;
  height: 20px;
}

table.loader tr td.td-2 {
  width: 50px;
}

table.loader tr td.td-2 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 50px;
  height: 50px;
}

table.loader tr td.td-3 {
  width: 75%;
}

table.loader tr td.td-3 span {
  height: 12px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

table.loader tr td.td-5 {
  width: 100px;
}

table.loader tr td.td-5 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 30px;
}
.paddingforcardbody {
  padding: "1rem" !important;
}
.inputBoxCss {
  border: "1px solid #ced4da" !important;
  padding: 11px;
  border-radius: 4px;
  color: "grey";
}

.submit-btn {
  border: none;
  color: white;
  background-color: #4caf50; /* Green */
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.submit-btn-orange {
  background-color: #60a44b; /* Orange */
}
.submit-btn:hover {
  opacity: 0.8;
}

.submit-icon {
  margin-right: 8px;
}

.profilepic {
  width: "90px";
  height: "100px";
  object-fit: cover;
  border-radius: 50%;
}

.not-active {
  pointer-events: none;
  cursor: default;
  opacity: 0.1;
}
.deleteactive {
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.css-m5vj9m-MuiStepper-root {
  margin-top: -50px !important;
}
.css-jcg04x {
  margin-top: 8px !important;
}
.bgUpload {
  border: 1px solid #1976d2 !important;
  background-color: #e7f1ff;
  font-size: 14px !important;
}
.bgSuccessClass {
  background-color: #60a44b !important;
  color: white !important;
  // margin-top: -30px !important;
}
.bgSuccessClassDisabled {
  background-color: #60a44b !important;
  color: white !important;
  // margin-top: -30px !important;
  pointer-events: none;
  opacity: 0.5;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: orange !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: orange !important;
}
.viewDetailsMapClass {
  background-color: orange !important;
  color: white !important;
  border: 1px solid orange !important;
  letter-spacing: 2px;
}
.textlistHide {
  list-style: none;
}
.muiDefaultButton {
  background-color: #f8b425 !important ;
  border-color: white !important;
}
.muiDefaultButton :hover {
  color: white;
}

.rs-picker-menu {
  z-index: 1000 !important;
}
.css-dojl2-MuiContainer-root {
  max-width: 100% !important;
}
.css-1bnhfwg-MuiGrid-root > .MuiGrid-item {
  width: 100% !important;
}
.css-jcg04x {
  /* max-width: 600px; */
  max-width: 100% !important;
}
.css-15j76c0 {
  width: 100% !important;
  max-width: 100% !important;
}

// .css-1usso8h-MuiDataGrid-root .MuiDataGrid-withBorderColor {
//   height: 104px !important;
// }
.content {
  height: 82vh;
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
}

.content::-webkit-scrollbar {
  width: 12px; /* For Chrome, Safari, and Opera */
}

.content::-webkit-scrollbar-thumb {
  background-color: transparent; /* For Chrome, Safari, and Opera */
}
.contentWrapper {
  overflow: hidden;
}

.card22 {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: none;
}
.card-body22 {
  padding: 30px;
}
.btn-primary22 {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.form-submit {
  // padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  // text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  width: 100px;
  height: 75px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  // text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}
.boxShadowClass {
  box-shadow: 0 0 3px #939393;
}
a:hover,
a:focus {
  text-decoration: none;
}

.h1 {
  font-size: 1.875rem !important;
}
h2,
.h2 {
  font-size: 1.5rem !important;
}
h3,
.h3 {
  font-size: 1.25rem !important;
}
h4,
.h4 {
  font-size: 1.125rem !important;
}
h5,
.h5 {
  font-size: 1rem !important;
}
h6,
.h6 {
  font-size: 0.875rem !important;
}
p {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $type1 !important;
  font-weight: 700 !important;
}
p {
  font-size: $default-font-size !important;
}
.display-1 {
  font-size: 3.75rem !important;
  @media (max-width: 991px) {
    font-size: 3rem !important;
  }
}
.display-2 {
  font-size: 2.8rem !important;
  @media (max-width: 991px) {
    font-size: 1.2rem !important;
  }
}
.display-3 {
  font-size: 2.5rem !important;
  @media (max-width: 991px) {
    font-size: 2rem !important;
  }
  @media (max-width: 576px) {
    font-size: 1.2rem !important;
  }
}
.display-4 {
  font-size: 1.875rem !important;
  @media (max-width: 991px) {
    font-size: 1.5rem !important;
  }
}
.display-5 {
  font-size: 1.25rem !important;
  @media (max-width: 991px) {
    font-size: 1rem !important;
  }
}
.custom-btn-fs {
  font-size: 0.875rem !important;
  @media (max-width: 991px) {
    font-size: 0.5rem !important;
  }
}
.blockquote {
  padding: 1.25rem !important;
  border: 1px solid $border-color !important;
}

address {
  p {
    margin-bottom: 0 !important;
  }
}

//blockqoute color variations
@each $color, $value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}
.error-page {
  h1 {
    font-size: 12rem !important;
    @media (max-width: 991px) {
      font-size: 8rem;
    }
  }
}
.icon-lg {
  font-size: 3.438rem !important;
}
.icon-md {
  font-size: 1.875rem !important;
}
.icon-sm {
  font-size: 1rem !important;
}
.fontBlue {
  color: #1976d2;
  font-weight: 500;
}
.letterSpacingClass {
  letter-spacing: 1px;
}
.tag {
  // float: left;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  color: #1976d2;
  font-weight: bold;
}
.text-green {
  color: #f8a102;
}
.boxColor {
  height: 20px;
  width: 15px;
  // margin-bottom: 15px ;
  // border: 1px solid black;
  margin-bottom: 12px;
}

.redColor {
  background-color: red !important;
}
.lightColor {
  background-color: #8fce00 !important;
}
.greenColor {
  background-color: green !important;
}
.orangeColor {
  background-color: orange !important;
}
.blueColor {
  background-color: #006acc !important;
}
.beigeColor {
  background-color: #fff2cc !important;
}
.lightForestcolor {
  background-color: #b7e1cd !important;
}
.fallowColor {
  background-color: yellow !important;
}
.OtherColor {
  background-color: #e5c9d7 !important;
}
.purpleColor {
  background-color: #a22291 !important;
}
.pinkColor {
  background-color: #fc8b9a;
}
.brownColor {
  background-color: #905058 !important;
}
.blackColor {
  background-color: black;
}
.skyColor {
  background-color: #8378fd !important;
}
.redShadeColor {
  background-color: #fd1500 !important;
}
.averageColor {
  background-color: #feffbe;
}
.wheatHarvested {
  background-color: #97fa97;
}
.mustardStanding {
  background-color: #ffaf2f;
}
.lentilHarvested {
  background-color: #f39b8b;
}
.lentilStanding {
  background-color: #bf3219;
}
.gramHarvested {
  background-color: #70bee4;
}
.gramStanding {
  background-color: #460ebe;
}

.maizeStandingColor {
  background-color: #ffeda0;
}
.JowarHarvestedColor {
  background-color: #c994c7;
}
.JowarStandingColor {
  background-color: #a0228f;
}
.BajraHarvestedColor {
  background-color: #efa2ac;
}
.BajraStandingColor {
  background-color: #fc8b9a;
}
.GrouHarvestedColor {
  background-color: #8f7277;
}
.GrouStandingColor {
  background-color: #905058;
}
.soyHarvestedColor {
  background-color: #afa8ff;
}
.soyStandingColor {
  background-color: #8378fd;
}
.OtherHarvestedColor {
  background-color: #fa8e84;
}
.backgroundColorBeige {
  background-color: white;
  border: 1px solid #f1ecec;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-right: 2px solid orange;
}
.backgroundColorBeigeHealth {
  background-color: white;
  border: 1px solid #f1ecec;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-right: 2px solid orange;
  height: 550px;
  overflow: scroll;
}
.cardBodyBozShadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.filterAnimation {
  transition: width 2s ease; /* Adjust the duration and easing function as needed */
}

.parentClassFilter fieldset {
  // background-color:#f6f6f6dd !important;
  // border:0px !important;
}
.parentClassFilter.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 6px !important;
}
.parentClassFilter .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 14px !important;
  height: 35px !important;
}

.parentClassFilter .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
}
.customBackgroundColor {
  background-color: #f8f8f8;
  // border-radius:15px;
}
.boxMarginUpload {
  margin: 29px 42px 33px;
}

.card-blockSample h5 {
  // color: #fff;
  // color:#878A99
}
.textGreyShade {
  color: #878a99;
}
.bg-c-blue-textColor {
  color: #4e73e5;
}
.bg-c-blueSample {
  // background: linear-gradient(45deg,#4099ff,#73b4ff);
  background-color: #edf1fc;
  padding: 2px;
  border-radius: 5px;
  color: #4e73e5;
}
.bg-c-green-textColor {
  color: #17d1bd;
}
.bg-c-greenSample {
  // background: linear-gradient(45deg,#2ed8b6,#59e0c5);
  background-color: #e8faf8;
  padding: 2px;
  border-radius: 5px;
  color: #17d1bd;
}
.bg-c-yellow-textColor {
  color: #ffc868;
}
.bg-c-yellowSample {
  // background: linear-gradient(45deg,#FFB64D,#ffcb80);
  background-color: #fffaf0;
  color: #ffc868;
  padding: 2px;
  border-radius: 5px;
}

.blueSample {
  background-color: #edf1fc !important;
}
.greenSample {
  background-color: #e8faf8 !important;
}

.yellowSample {
  background-color: #fffaf0 !important;
}
.cardSample:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
.text-blues {
  color: #007bff !important;
}

.text-muted {
  color: #6c757d !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cardSample {
  border-radius: 12px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: #f9f9f9;

  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cardSample .card-blockSample {
  padding: 16px;
}

.order-cardSample i {
  font-size: 26px;
}
.bg-c-blueAmount {
  // background: linear-gradient(45deg,#4099ff,#73b4ff);
  background: #e1ebfd;
}
.border_right {
  border-right: 1px solid grey;
}
.button_all {
  color: #746b6b;
  background-color: rgb(237 235 235);
  padding: 5px;
  border-radius: 2px;
  font-size: 13px;
}
.custom-cell {
  margin-left: 10px;
}
.select_box {
  padding: 14.5px 14px !important;
}
.css-17mclh2-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 14.5px 14px !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 14.5px 14px !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 8.5px 10px !important;
}
.textBlue {
  color: #1976d2;
}
.kanban-item {
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
}
.kanban-item {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.kanban-container * {
  box-sizing: border-box;
}
.kanban-item .kanban-box {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 25px;
  position: relative;
  display: block;
  color: inherit;
  background-color: #fff;
}
.kanban-item .kanban-box .date {
  font-size: 13px;
  opacity: 0.5;
}
.kanban-item .kanban-box .badge {
  font-weight: 400;
}
.kanban-item .kanban-box .badge {
  line-height: 13px;
}
.badge {
  padding: 0.44em 0.7em;
  font-family: "Outfit", sans-serif, sans-serif;
  font-weight: 500;
}
.badge-danger {
  // background-color: #E44141;
  background-color: rgba(236, 69, 97, 0.18);
  color: #ec4561;
}
.badge-warning {
  background-color: orange;
}
.badge-success {
  background-color: #4caf50;
}
.badge-icon {
  font-size: 12px !important;
}
.badge-black {
  background-color: black;
}
.badge-blue {
  background-color: #1976d2;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  // width: 100%;
  // max-width: 867px;
  // margin: 25px 10px;
  height: calc(100% - 50px);
  // border:  2px solid #ddd;
  border-radius: 5px;
  // box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 8px;
  border-radius: 15px;
  background-color: #d1e7dd;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.msg-info-name {
  margin-right: 10px;
  font-size: 0.8em;
}
.msg-info-time {
  font-size: 0.75em;
}
.msg-text {
  font-size: 0.8em;
}
.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: #dff4fc;
  color: black;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: 2px solid #ddd;
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.msger-chat {
  background-color: #fcfcfe;
}
.heightChat {
  // height:200px;
  // min-height:200px ;
  // overflow-y: auto;
}
.msger-inputText {
  flex: 1;
  background: #f4f4f4;
  padding: 7px;
  border-radius: 7px !important;
}
.accordionColor {
  //  background-color:  rgb(237, 244, 251) !important;
  background-color: #eee9fc !important;
  color: #6541e4 !important;
}
.accordionBodyColor {
  background-color: rgb(249 252 255) !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 51px !important;
}

.flex-container {
  display: flex;
}
.flex-check,
.flex-title {
  flex: 1;
}

.c-selection {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 450;
}

.c-selection-card {
  border: 2px solid #d6dde3;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  flex: 1;
  background-color: white !important;

  &__heading {
    color: #394956;
    font-size: 12px;
    letter-spacing: 1.85px;
    text-transform: uppercase;
  }

  &__price {
    color: #394956;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 32px;
    font-weight: 500;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }

  &__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__currency {
    font-size: 20px;
    margin-top: -4px;
  }

  &__frequency {
    color: #93a1af;
    font-size: 12px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
    transition: all 0.1s ease-in-out;
  }

  &.selected {
    border: 2px solid #3096d5;
    transition: all 0.5s ease;
  }
}

.selection-gap > * {
  margin-left: 20px;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

.footer-item-right {
  color: #9b9b9b;
  font-size: 12px;
  margin-right: 10px;
}

.footer-item-left {
  color: #4bc27d;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
}

.text-grey {
  color: #9b9b9b;
  font-weight: 400;
}
.text-successs {
  color: #2e7d32;
}
.text-purple {
  color: purple;
}
.text-blue {
  color: #1677ff;
}

.text-orange {
  color: orange;
}
.css-1b3etnh {
  margin-top: 0px !important;
}
.ant-tag-processing {
  color: #1677ff !important;
  background: #e6f4ff !important;
  border: 1px solid #91caff !important;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
}
.invoice_1 {
  font-size: 10px;
  background-color: #fffaf0;
  color: orange;
  padding: 6px;
  border-radius: 5px;
}
.contract_note {
  font-size: 10px;
  background-color: #fffbe6;
  color: #d48806;
  padding: 6px;
  border-radius: 5px;
}
.generate_invoice {
  font-size: 12px;
  background-color: #edf1fc;
  padding: 4px;
  border-radius: 5px;
  color: #4e73e5;
}

.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  /* padding: 7.5px 4px 7.5px 5px; */
  padding: 4px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 16px !important;
}
.adminApprovebutton {
  // color: #03900e !important;
  // background: #e8faf8 !important;
  color: white !important;
  background: #03900e !important;
  font-weight: 700 !important;
  border: 1px solid #03900e52 !important;
  box-shadow: none !important;
}
.adminApprovebuttonPending {
  color: orange !important;
  background: #fffaf0 !important;
  font-weight: 700 !important;
  border: 1px solid orange !important;
  box-shadow: none !important;
}

.position-relative {
  position: relative;
}

.eye-iconPassword {
  position: absolute !important;
  top: 53px !important;
  right: 9px !important;
  transform: translateY(-50%) !important;
  cursor: pointer !important;
}
.list-unstyled {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.container_tabs {
  display: flex;
  background-color: #e9ecef;
  background-size: 200% 200%;
  animation: gradient 16s ease infinite;

  .tabs_wrapper {
    display: flex;
    align-items: center;
    border-radius: 10px;
    position: relative;
    width: 100%;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

  .tab {
    border-radius: 10px;
    vertical-align: middle;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #232323;
    user-select: none;
    transition: all 0.4s;
    flex-grow: 1;
    flex-basis: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .tab:hover {
    color: #ff6f0f;
    cursor: pointer;
  }

  .tab.active {
    color: #ff6f0f;
    background-color: white;
  }
  .material-icons {
    vertical-align: middle;
    margin-right: 3px;
    position: relative;
  }
  .title {
    vertical-align: middle;
  }
  .badge-soft-warning {
    color: #ff6f0f !important;
    background-color: white;
  }
  input {
    display: none;
  }
}

.farmer_card {
  background-color: rgb(255 244 201 / 49%);
}
.badge-soft-warning {
  // background-color: rgba(248, 180, 37, .18);
  color: orange !important;
  background-color: white;
}
.downloadButtonPdfCss {
  display: inline-block !important;
  outline: 0;
  appearance: none;
  padding: 0px 12px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  background-color: rgb(249, 251, 250) !important;
  border: 1px solid rgb(137, 151, 155) !important;
  box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px !important;
  color: rgb(61, 79, 88) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 36px !important;
  transition: all 150ms ease-in-out 0s !important;
  :hover {
    color: rgb(61, 79, 88) !important;
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(93, 108, 116) !important;
    box-shadow: rgb(0 0 0 / 30%) 0px 4px 4px, rgb(231 238 236) 0px 0px 0px 3px !important;
  }
}
.cardDesign {
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5),
    5px 5px 5px rgba(0, 0, 0, 0.05), -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
}
.grayBorderRight {
  border-right: 1px solid #ece8e8;
}
.backgroundColorTransparent {
  background-color: transparent;
}
.remarksColor {
  // color: #f8a33a;
  color: #ec4561;
}
.buttonTable {
  display: inline-block;
  outline: none;
  padding: 0 16px;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #dddbda;
  // color: #0070d2;
  font-size: 13px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  :hover {
    background-color: #f4f6f9;
  }
}
.no-pointer {
  cursor: not-allowed !important;
}
.rejectButtonColor {
  color: #d92736;
}
.textareaContainer {
  display: flex;
  align-items: center;
}

.textareaContainer textarea {
  flex: 1; /* Take up remaining space */
  margin-right: 10px; /* Add margin between textarea and buttons */
}
.remarksCell {
  user-select: none !important;
}
.MuiDataGrid-columnHeaders {
  background-color: #e9ecef !important;
}

.radio-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  gap: 20px; /* Add space between items */
}

.radio-label {
  display: flex;
  align-items: center; /* Center items vertically */
}
.multistep-button.previous-button {
  background-color: blue !important;
  color: white !important;
}

.multistep-button.next-button {
  background-color: blue !important;
  color: white !important;
}
.steelblue {
  color: steelblue;
}
.indexStyle {
  background-color: #edf1fc;
  color: #4e73e5;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 8px;
}

.indexStyle2 {
  background-color: #f57f4736;
  color: #de6b1e;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 8px;
}
.orange_text {
  color: #fd9444;
}
.input_text {
  font-size: 15px !important;
}
.form_text {
  font-size: 16px !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 12px !important;
}

.navigaton_btn {
  display: flex;
  list-style: none;
}
.category_font {
  font-size: 10px;
}
.MuiTextArea .MuiInputBase-input {
  margin-top: -5px !important;
}
.MuiTextArea .MuiOutlinedInput-notchedOutline {
  height: 48px !important;
}
.MuiTextArea2 .MuiInputBase-input {
  margin-top: -5px !important;
}
.MuiTextArea2 .MuiOutlinedInput-notchedOutline {
  height: 45px !important;
}
.MuiTextArea3 .MuiInputBase-input {
  margin-top: -5px !important;
}
.MuiTextArea3 .MuiOutlinedInput-notchedOutline {
  height: 40px !important;
  background-color: white !important;
}

.react-dropdown-tree-select .dropdown {
  width: 100% !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.tag-list .tag-item {
  width: 93% !important;
}
.dropdown-tree-select .dropdown-menu {
  z-index: 10000; /* Adjust the z-index as needed */
}

.dropdown-tree-select .dropdown-toggle .dropdown-caret {
  font-size: 1.5em; /* Increase the size of the "+" icon */
}
.react-dropdown-tree-select .dropdown .dropdown-content {
  z-index: 100 !important;
  padding: 14px !important;
}

.form_class {
  padding: 11px !important;
  border: 1px solid rgba(151, 151, 151, 0.3) !important;
  font-size: 15px !important;
}
.input_text2 {
  font-size: 14px !important;
}
.TextInput {
  font-size: 14px !important;
  padding: 10px;
}
.user_table tr td {
  font-size: 14px;
  font-weight: 600;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
  border: 0.1px solid black;
}
.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }
}
.document_modal {
  width: 70%;
}
.custom-dropdown .dropdown-content {
  max-height: 300px; /* Set your desired max-height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  padding: 10px; /* Optional: Add padding for better appearance */
}

/* Additional styling for better appearance */
.custom-dropdown .dropdown-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.custom-dropdown .dropdown-content li {
  padding: 5px 10px;
}
.custom-dropdown .dropdown-content li .tag {
  display: none;
}
.custom-dropdown .tag {
  display: none;
}

.custom-dropdown .tag-item .search {
  border-bottom: 1px solid transparent !important;
}

// .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
//   cursor: pointer;
//   width: 90% !important;
// }
// .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
//   float:end !important;
//   margin-top: 9px;
// }
// .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:before {
//   float:end !important;
//   margin-top: 9px;
// }
.container-nestedData {
  max-width: 800px;
  // margin: 40px auto;
  padding: 20px;
  // background-color: #f9f9f9;
  background-color: white;

  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.item-nested {
  border-bottom: 1px dashed lightgrey;
  margin-bottom: 13px;
}
.item-nested:hover {
  // background-color: white;
  background-color: #f5f7fa;
  cursor: pointer;
  // padding:5px
}

.dropdown-nested {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  // background-color: #fff;
  background-color: white;

  max-height: 250px;
  overflow-y: auto;
}

.ranges {
  padding: 7px;
}

.range-item {
  margin-bottom: 10px;
}

.range-item input[type="radio"] {
  margin-right: 10px;
}

.range-item span {
  margin-left: 10px;
}

.form_class {
  padding: 11px !important;
  border: 1px solid rgba(151, 151, 151, 0.3) !important;
  font-size: 15px !important;
}
.input_text2 {
  font-size: 14px !important;
}
.user_table tr td {
  font-size: 14px;
  font-weight: 600;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
  border: 0.1px solid black;
}
.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }
}
.document_modal {
  width: 70%;
}
.parametersClass {
  margin-top: 8px;
  padding: 6px;
  background-color: #edf1fc;
  border-radius: 8px;
  color: #4e73e5;
  // box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.data_card .commission_card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border: 1px solid orange;
  transition: all 0.3s ease-in-out;
}
.commission_card {
  margin-bottom: 0px !important;
  cursor: pointer;
}
.clickedCard {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border: 1px solid orange;
  transition: all 0.3s ease-in-out;
}
.userCard {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.column {
  padding-left: 2rem;
}
// .column:hover {
//   padding-left: 0;
// }
// .column:hover .test_card .txt {
//   margin-left: 1rem;
// }
.column:hover .test_card .txt h1,
.column:hover .test_card .txt p {
  color: rgba(255, 255, 255, 1);
  opacity: 1;
}
.column:hover .anchor {
  color: rgba(255, 255, 255, 1);
}

.gr-1 {
  background: linear-gradient(145deg, #01e4f8 0%, #1d3ede 100%);
}

.gr-2 {
  background: linear-gradient(145deg, #b4ec51 0%, #429321 100%);
}

.gr-3 {
  background: linear-gradient(145deg, #c86dd7 0%, #5343e9 100%);
}

// *{transition: .5s;}

.h-100 {
  height: 100vh !important;
}

.card_font div h6 {
  font-size: 12px !important;
}
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
}
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
}
.center_class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.commission_percentage_bg {
  background-color: #007bff;
  padding: 5px 10px;
  border-radius: 5px;
}

.voutchers {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  // width: 10px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
}
.voutcher-divider {
  display: flex;
}
.voutcher-left {
  width: 100%;
}
.voutcher-name {
  color: black;
  font-size: 13px;
}
.voutcher-code {
  color: red;
  font-size: 11px;
  font-weight: bold;
}
.voutcher-right {
  width: 20%;
  // background-color: #800080b8;
  background: linear-gradient(145deg, #c4b569 0%, #e94343 100%);
  color: #fff;
}

.discount-percent {
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.off {
  font-size: 14px;
  position: relative;
  bottom: 5px;
}
.product_table th {
  font-size: 15px;
  color: #000000;
}
.commission_table th {
  font-size: 15px;
  color: #191919;
}
// .selected-row {
//   background-color: rgb(241, 156, 156);
// }
.commision_btn {
  background: #e6f4ff !important;
  padding: 2px;
  border-radius: 4px;
}
.commision_btn2 {
  background: #a1f93588 !important;
  padding: 2px;
  border-radius: 4px;
}
.commission_table tr {
  padding: 20px;
}

.ico-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.test_icon {
  position: relative;
  right: -50%;
  top: 60%;
  font-size: 12rem;
  line-height: 0;
  opacity: 0.2;
  color: rgba(255, 255, 255, 1);
  z-index: 0;
}
.filter_class {
  position: absolute;
  top: -16px;
  left: 10rem;
  width: 500px;
  z-index: 2;
  border-right: 2px solid rgb(255, 218, 151);
  box-shadow: 0 7px 14px 0px rgba(0, 0, 0, 0.14);
  background-color: white;
  padding: 12px;
  border-radius: 8px;
}
.cardblockPadding {
  padding: 5px !important;
}
.farmer-info-wrapper {
  line-height: 1.5;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}
.headingColor {
  background-color: #e7f2fa;
}
.c-dashboardInfo {
  position: relative;
}
.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}
.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  // text-align: center;
  position: relative;
  overflow: hidden;
  padding: 30px 25px 20px;
  // height: 100%;
}

.c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}
.c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}
.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}
.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}
.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}

.wrapper {
  --font-color-dark: #323232;
  --font-color-light: #fff;
  --bg-color: #fff;
  --main-color: #323232;
  --secondary-color: #505050;
  // position: relative;
  // width: 200px;
  height: 36px;
  background-color: var(--bg-color);
  border: 1px solid rgb(196, 196, 196);
  border-radius: 34px;
  display: flex;
  flex-direction: row;
}

.commission_option {
  height: 30px;
  width: 49%;
  position: relative;
  top: 2px;
  left: 2px;
  border-radius: 34px;
  transition: 0.25s cubic-bezier(0, 0, 0, 1);
}

// .commission_option:hover {
//   background-color: rgba(237, 164, 28, 0.614);
// }

.commission_option:hover .span_title {
  color: black;
}

.commission_input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  appearance: none;
  cursor: pointer;
}

.radio_btn {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span_title {
  color: var(--font-color-dark);
}

.commission_input:checked + .radio_btn {
  background: linear-gradient(145deg, #e7d167 0%, #e7783d 100%);
  // background-color: rgb(237, 164, 28);
  transition: 0.2s cubic-bezier(0, 0, 0, 1);
}

.commission_input:checked + .radio_btn .span_title {
  color: var(--font-color-light);
  transition: 0.25s cubic-bezier(0, 0, 0, 1);
}

.upper {
  height: 100px;
}

.upper img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.user {
  position: relative;
}

.profile img {
  height: 60px;
  width: 60px;
  margin-top: 2px;
}

.profile {
  position: absolute;
  top: -70px;
  left: 35%;
  height: 70px;
  width: 70px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.follow {
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
}

.stats span {
  font-size: 20px;
}
.user_badge_icon {
  position: absolute;
  left: 6px;
  top: 6px;
}

.wrapper2 {
  width: 255px;
  margin: 0 auto;
}
.card-loader {
  background-color: #fff;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08),
    0 -1px 3px 0 rgba(0, 0, 0, 0.06);
  padding: 8px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
  height: 270px;
  overflow: hidden;

  &:only-child {
    margin-top: 0;
  }

  &:before {
    content: "";
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }

  &:after {
    content: "";
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}

// Loader animation
@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
.ledgres_card {
  width: 49.5%;
}
.comission_dashboard_filter {
  position: absolute;
  top: -21px;
  left: 6.8rem;
  width: 680px;
  z-index: 2;
  border-right: 2px solid rgb(255, 218, 151);
  box-shadow: 0 7px 14px 0px rgba(0, 0, 0, 0.14);
  background-color: white;
  padding: 12px;
  border-radius: 8px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0 !important;
  }
  .custom-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .custom-buttons-container button {
    margin: 5px 0;
    margin-right: 10px;
  }
  .preview-list {
    width: 40% !important;
    position: absolute !important;
    inset: 0px auto auto 0px !important ;
    transform: translate3d(128.6667px, 66px, 0px) !important;
    -webkit-transform: translate3d(128.6667px, 66px, 0px) !important;
    -moz-transform: translate3d(128.6667px, 66px, 0px) !important;
    -ms-transform: translate3d(128.6667px, 66px, 0px) !important;
    -o-transform: translate3d(128.6667px, 66px, 0px) !important;
  }
  .css-64nmm9 {
    width: auto !important;
  }
  .ledgres_card {
    width: 100%;
  }
  .content-wrapper {
    padding: 5px !important;
  }
  .backgroundColorBeigeHealth {
    height: 350px !important;
    overflow: scroll;
  }
  .content {
    height: 62vh !important;
    overflow-y: scroll;
  }
  h4 {
    font-size: 1rem !important;
  }
}
.iconsize {
  color: orange;
  font-size: 16px;
}
.css-hyum1k-MuiToolbar-root {
  display: none !important;
}
.css-i6s8oy {
  display: none !important;
}
.documentBadge {
  font-size: 12px;
  background-color: #f9f0ff;
  padding: 4px;
  border-radius: 5px;
  color: #8158c2;
}
.hrBorder {
  border-top: 1px solid #0c63e4 !important;
}
.headerColor {
  background-color: #f5f5f5;
}
.onboardingBgColor {
  background-color: #fffaf1;
}

//   .form {
//     display: flex;
//     flex-direction: column;
//   }

//   .form__title {
//     text-align: center;
//     margin-bottom: 1.5rem;
//   }

//   .form__group {
//     display: grid;
//     grid-template-columns: auto 1fr;
//     margin-bottom: 0.5rem;
//     border: 1px solid #ccc;
//     border-radius: 0.25rem;
//     padding: 0.5rem;
//     transition: border 0.3s, background-color 0.3s;
//   }

//   .form__group:hover {
//     border: 1px solid #9cbce2;
//     background-color: #f2f8ff;
//   }

//   .form__icon {
//     padding: 0.75rem;
//     border-radius: 0.25rem;
//     background-color: #ddd;
//   }

//   .form__input {
//     padding: 0rem 1rem;
//     border: none;
//     outline: none;
//     border-radius: 0.25rem;
//     background-color: transparent;
//     font-size: 0.875rem;
//     font-family: "Arial", sans-serif;
//   }

//   .form__input--mobile {
//     padding: 0rem;
//   }

//   .form__mobile-container {
//     display: grid;
//     grid-template-columns: auto 1fr;
//     align-items: center;
//   }

//   .form__mobile-label {
//     font-size: 0.875rem;
//     margin-left: 1rem;
//     font-weight: 600;
//     color: #777;
//   }

//   .form__err {
//     display: none;
//     color: red;
//     font-size: 0.75rem;
//     margin-top: 0.5rem;
//     margin-bottom: 0.5rem;
//   }

//   .form__select {
//     border: none;
//     border-radius: 0.25rem;
//     outline: none;
//     background-color: transparent;
//     padding: 0rem 1rem;
//   }

//   .form__text-container {
//     margin-block: 1rem;
//   }

//   .form__text-container p {
//     text-align: center;
//     font-size: 0.875rem;
//     margin-bottom: 0.5rem;
//     color: #777;
//   }

//   .form__button {
//     background-color: #f59d0e;
//     color: white;
//     border: none;
//     border-radius: 0.25rem;
//     padding: 1rem;
//     margin-top: .5rem;
//     cursor: pointer;
//     font-size: 1rem;
//     font-weight: 500;
//     transition: background-color 0.3s;
//   }

//   .form__button:hover {
//     background-color: #dc352d;
//   }
//   .form__icon {
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     background: #007bff;
//     color: white;
//     border-radius: 50%;
//     padding: 5px;
//     cursor: pointer;
//     transition: background 0.3s;
// }

// .form__icon:hover {
//     background: #0056b3;
// }

// .form__icon .fa-arrow-right {
//     font-size: 16px;
// }

.cardBlue {
  color: #0284c7;
  background-color: #e3effc;
}
.bg-light-warning {
  background-color: #fff8dd !important;
  color: #ffc700;
}
.bg-light-red {
  background-color: #fcc9d6 !important;
  color: #f1416c;
}
.text-black {
  color: #5f5e5eed !important;
}
.card-header {
  color: #5f5e5eed !important;
}
.card h5 {
  color: #5f5e5eed;
}
.accordion-header button {
  color: #5f5e5eed;
}

// .is-invalid {
//   border-color: #dc3545;
// }
// .is-invalid:focus {
//   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
// }
// :root {
//   --marineBlue: hsl(213, 96%, 18%);
//   --purplishBlue: hsl(243, 100%, 62%);
//   --pastelBlue: hsl(228, 100%, 84%);
//   --lightBlue: hsl(206, 94%, 87%);
//   --strawberryRed: hsl(354, 84%, 57%);
//   --coolGray: hsl(231, 11%, 70%);
//   --lightGray: hsl(229, 24%, 87%);
//   --magnolia: hsl(217, 100%, 97%);
//   --alabaster: hsl(231, 100%, 99%);
//   --white: hsl(0, 0%, 100%);
// }

// .onBoardingFormBody {
//   display: flex;
//   justify-content: center;
//   margin-top: 5rem;
//   font-family: 'Arial', sans-serif;
//   color: #333;
//   background-color: #fffaf1;
// }

// .formParentWrapper {
//   display: flex;
//   background: #fff;
//   border-radius: 10px;
//   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// }

// .steps {
//   padding: 2rem;
//   background: #f7f7f7;
//   border-radius: 10px 0 0 10px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// }

// .stepInfo {
//   display: flex;
//   align-items: center;
//   margin-bottom: 1.5rem;
// }

// .step {
//   width: 40px;
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 2px solid #ccc;
//   border-radius: 50%;
//   margin-right: 1rem;
//   font-size: 1.2rem;
//   transition: all 0.3s ease;
// }

// .step.active {
//   background-color: #4caf50;
//   color: white;
//   border: none;
// }

// .labelOnboarding {
//   font-weight: bold;
//   color: #666;
// }

// .rightSectionParent {
//   margin-left: 2rem;
//   width: 600px;
// }

// .rightSectionWrapper {
//   padding: 2rem;
//   background-color: #f9f9f9;
//   border-radius: 0 10px 10px 0;
// }

// .mainForm {
//   margin-bottom: 2rem;
// }

// .personal,
// .summary {
//   font-size: 1.5rem;
//   font-weight: bold;
// }

// .personalInfo,
// .summaryInfo {
//   margin-top: 0.5rem;
//   color: #888;
// }

// .fieldParent {
//   margin-top: 1rem;
// }

// .labelErrorParent {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .error {
//   color: red;
// }

// input[type="text"],
// input[type="email"],
// input[type="password"],
// input[type="file"],
// select {
//   width: 100%;
//   padding: 0.5rem;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   margin-top: 0.5rem;
// }

// .upload-container {
//   margin-top: 1rem;
// }

// .btnWrapperOnboarding {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 2rem;
// }

// .prevOnboarding {
//   cursor: pointer;
//   color: #4caf50;
//   display: inline-block;
//   padding: 0.5rem 1rem;
//   border: 2px solid #4caf50;
//   border-radius: 5px;
//   transition: all 0.3s ease;
// }

// .prevOnboarding:hover {
//   background-color: #4caf50;
//   color: white;
// }

// .hideBtn {
//   display: none;
// }

// .nextOnboarding {
//   background-color: #4caf50;
//   color: white;
//   padding: 0.5rem 1rem;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: all 0.3s ease;
// }

// .nextOnboarding:hover {
//   background-color: #45a049;
// }

// .thankContainer {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// }

// .thankParent {
//   text-align: center;
// }

// .thankyou {
//   font-size: 2rem;
//   font-weight: bold;
// }

// .thankMsg {
//   margin-top: 1rem;
//   color: #888;
// }
// .fieldParent {
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 1rem;
// }

// .labelErrorParent {
//   margin-bottom: 0.5rem;
// }

// .email {
//   padding: 0.5rem;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   flex: 1;
// }

// .inputWrapper {
//   display: flex;
//   align-items: center;
//   position: relative;
// }

// .submitButton {
//   background-color: green;
//   color: white;
//   border: none;
//   padding: 0.5rem 1rem;
//   border-radius: 4px;
//   cursor: pointer;
//   font-size: 1rem;
//   margin-left: -4px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   right: 1px;
//   position: absolute;
//   top: 7px;
// }

// .submitButton:hover {
//   background-color: darkgreen;
// }

// .submitButton:focus {
//   outline: none;
// }
// .otp-field {
//   flex-direction: row;
//   column-gap: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .otp-field input {
//   height: 30px;
//   width: 36px;
//   border-radius: 6px;
//   outline: none;
//   font-size: 1.125rem;
//   text-align: center;
//   border: 1px solid #ddd;
// }

// .otp-field input:focus {
//   box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
// }

// .otp-field input::-webkit-inner-spin-button,
// .otp-field input::-webkit-outer-spin-button {
//   display: none;
// }

// .btn-primary.ml-2 {
//   background-color: green;
//   color: white;
//   border: none;
//   padding: 0.5rem 1rem;
//   border-radius: 4px;
//   cursor: pointer;
//   font-size: 1rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 0;
//   margin-left: 10px;
// }

// .btn-primary.ml-2:hover {
//   background-color: darkgreen;
// }

// .btn-primary.ml-2:focus {
//   outline: none;
// }

// .resend {
//   font-size: 12px;
// }

.headerModalColor {
  background-color: #ffe8bd;
}

// #signup {
//   width: 600px;
//   height: auto;
//   padding: 20px;
//   background: #fff;
//   margin: 80px auto;
//   position: relative;
//   min-height: 300px;
// }
// #fieldsets {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;
//   padding: 20px;
//   box-sizing: border-box;
//   overflow: hidden;
// }
// input[type=text],
// input[type=email],
// input[type=password],
// input[type=tel],
// textarea {
//   display: block;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   width: 100%;
//   box-sizing: border-box;
//   border: 1px solid #ddd;
//   padding: 8px;
//   margin-bottom: 8px;
//   position: relative;
//   &:focus {
//     outline: none;
//     border: 1px solid darken(#2cbab2,10%);
//   }
// }

// input[type=radio]{
//   margin: 6px;
//   display: inline-block;
// }
// fieldset {
//   border: none;
//   position: absolute;
//   left: -640px;
//   width: 600px;
//   padding: 10px 0;
//   transition: all 0.3s linear;
//   -webkit-transition: all 0.3s linear;
//   -moz-transition: all 0.3s linear;
//   -ms-transition: all 0.3s linear;
//   opacity: 0;
//   &.current {
//     left: 20px;
//     opacity: 1;
//   }
//   &.next {
//     left: 640px;
//   }
// }
// input[type=submit] {
//   display: none;
//   border: none;
// }
// #section-tabs {
//   font-size: 0.8em;
//   height: 50px;
//   position: relative;
//   margin-top: -50px;
//   margin-bottom: 50px;
//   padding: 0;
//   font-weight: bold;
//   list-style: none;
//   text-transform: uppercase;
//   li {
//     color: #a7a7a7;
//     span {
//       color: #bababa;
//     }
//     cursor: not-allowed;
//     &.active {
//       color: #444;
//       cursor: pointer;
//     }
//     border-left: 1px solid #aaa;
//     text-decoration: none;
//     padding: 0 6px;
//     float: left;
//     width: 25%;
//     box-sizing: border-box;
//     text-align: center;
//     font-weight: bold;
//     line-height: 30px;
//     background: #ddd;
//     position: relative;
//     &:after {
//       content: "";
//       display: block;
//       margin-left: 0;
//       position: absolute;
//       left: 0;
//       top: 0;
//     }
//     &.current {
//       opacity: 1;
//       background: #fff;
//       z-index: 999;
//       border-left: none;
//       &:after {
//         border: 15px solid transparent;
//         border-left: 15px solid #2cbab2;
//       }
//     }
//   }
// }
// .error {
//   color: #bf2424;
//   display: block;
// }
// input.error, textarea.error {
//   border-color: #bf2424;
//   &:focus {
//     border-color: #bf2424;
//   }
// }
// label.error {
//     margin-bottom: 20px;
// }
// input.valid {
//   color: green;
// }
// label.valid {
//   position: absolute;
//   right: 20px;
// }
// input+.valid, textarea+.valid {
//   display: none;
// }
// .valid+.valid {
//   display: inline;
//   position: absolute;
//   right: 10px;
//   margin-top: -36px;
//   color: green;
// }

// .btn {
//   border: none;
//   padding: 8px;
//   background: #2cbab2;
//   cursor: pointer;
//   transition: all 0.3s;
//   -webkit-transition: all 0.3s;
//   -moz-transition: all 0.3s;
//   &:hover {
//      background: darken(#2cbab2, 6%);
//   }
//   color: #fff;
//   position: absolute;
//   bottom: 20px;
//   right: 20px;
//   font-family: 'Merriweather Sans', sans-serif;
// }

// .table {
//   width: 100%;
//   border-collapse: separate;
//   border-spacing: 0;
// }

// .table thead th {
//   background-color: #f8f9fa;
//   text-align: center;
// }

// .table tbody td {
//   vertical-align: middle;
// }

// .select-img {
//   width: 20px;
//   height: 20px;
//   margin-right: 10px;
// }

// .form-control {
//   border-radius: 4px;
//   border: 1px solid #ced4da;
//   padding: 8px;
//   font-size: 14px;
// }

// input.form-control {
//   padding: 10px;
// }

// select.form-control {
//   padding: 8px;
//   background-color: #ffffff;
// }

// input::placeholder {
//   color: #6c757d;
// }

// input:focus, select:focus {
//   border-color: #80bdff;
//   outline: none;
// }

// input::placeholder {
//   color: #6c757d !important;
// }
.bgWhite {
  background-color: white !important;
}
.smooth-modal .modal-dialog {
  transition: all 0.3s ease; /* Smooth transition for the modal */
}

.smooth-modal .modal-content {
  border-radius: 10px; /* Rounded corners for a modern look */
  border: none; /* Remove border for a clean look */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.smooth-modal .modal-header {
  border-bottom: none; /* Remove bottom border */
}

.smooth-modal .modal-footer {
  border-top: none; /* Remove top border */
}
.custom-scrollable-table {
  max-height: 400px; /* Set the height you want for the scrollable area */
  overflow-y: auto;
  border: 1px solid #e0e0e0; /* Optional border for better visual separation */
}

.custom-scrollable-table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

.custom-scrollable-table tbody {
  // background-color: #fdf7f2;
}

.custom-scrollable-table tr {
  transition: background-color 0.3s ease;
}

.custom-scrollable-table tr:hover {
  background-color: #f1f1f1; /* Light grey hover effect */
}

.custom-scrollable-table th,
.custom-scrollable-table td {
  padding: 12px;
  white-space: nowrap;
}

.custom-scrollable-table td {
  border-bottom: 1px solid #e0e0e0;
}

.custom-scrollable-content {
  max-height: 100%; /* You can adjust the height as needed */
}

.scrollable-content {
  max-height: 200px; /* Adjust the height to control the scrollable area */
  overflow-y: auto;
}
.season-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  font-family: "Arial", sans-serif;
}

.season-label {
  font-size: 16px;
  color: #333;
  margin-right: 10px;
  font-weight: bold;
}

.season-dropdown {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  color: #333;
  transition: border-color 0.3s ease;
}

.season-dropdown:hover {
  border-color: #2e93fa;
}

.custom-tabs-container {
  width: 100%;
  padding: 10px 0;
}

.custom-tabs {
  border-bottom: 1px solid #ddd;
}

.custom-tab {
  font-size: 16px;
  font-weight: bold;
  margin: 0 5px;
  text-transform: none;
}

.custom-tabs .MuiTabs-indicator {
  height: 3px; /* Adjust thickness */
  background-color: #1976d2; /* Customize color */
}

.custom-tabs .MuiTab-root {
  padding: 6px 16px; /* Adjust padding */
  border-radius: 4px; /* Rounded corners */
}

.custom-tabs .MuiTab-root.Mui-selected {
  color: #1976d2; /* Color of the selected tab */
}

.status-section {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.status-header {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.status-badge {
  background-color: #28a745; /* Green background for status */
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1rem;
}

.ml-2 {
  margin-left: 10px;
}

.custom-border-table {
  border: 1px solid #e0e0e0 !important; /* Light grey border */
}
.custom-border-table th,
.custom-border-table td {
  border: 1px solid #f0f0f0 !important; /* Even lighter grey for table cells */
}
@media (max-width: 576px) {
  .modal-content {
    padding: 10px;
  }
  .modal-header {
    font-size: 1rem;
  }
  .form-control {
    font-size: 0.9rem;
  }
  .btn {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
}
.removeMarginBottom {
  @media (max-width: 576px) {
    margin-bottom: 0 !important;
  }
}
.font-h4Class {
  @media (max-width: 576px) {
    font-size: 13px !important;
    line-height: 14px !important;
    font-weight: 700;
  }
}

.heading-with-underline {
  position: relative;
  display: inline-block; /* Ensures the underline only spans the text width */
}

.heading-with-underline::after {
  content: "";
  display: block;
  width: 100%; /* Full width of the text */
  height: 1px; /* Thinner line for a more subtle effect */
  background-color: #ccc; /* Color of the underline */
  position: absolute;
  bottom: -1px; /* Position it right under the text */
  left: 0; /* Align it to the left */
}
.hover-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: orange;
  font-weight: 600;
}

// onboarding login

.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #4c489d;
  box-shadow: 0px 2px 2px #5c5696;
  cursor: pointer;
  transition: 0.2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6a679e;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875b5;
}

.borderShade {
  border: 1px solid #fffdfd !important;
}

//mobile css procuremnet
.heading-title h5 {
  position: relative;
  display: inline-block; /* Ensure the underline sizes according to content width */
  margin-bottom: 10px; /* Adjust if needed */
}

.heading-title h5:after {
  content: "";
  width: 100px;
  height: 1px;
  position: absolute;
  background: orange;
  border-radius: 4px;
  top: 50%; /* Center vertically relative to the h5 text */
  left: 100%; /* Position it to the right of the h5 text */
  margin-left: 10px; /* Add space between text and line */
}
.fpo_card h6 {
  color: #5c5c5c !important;
}
// .pb-product.inactive {
//   box-shadow: 0 2px 4px 0 rgba(5, 47, 95, .04), 0 12px 16px 0 rgba(52, 105, 203, .12);
//   transition: .3s !important;
// }
// .pb-product .product-name {
//   font-size: 14px !important;
//   color: #052f5f;
//   font-weight: 700;
//   margin-bottom: 8px;
//   text-transform: uppercase;
//   transition: all .3s;
//   width: 65%;
// }
// .pb-product {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 160px;
//   padding: 16px 14px 0;
//   background-color: #fff;
//   border-radius: 8px;
//   border: 1px solid rgba(235, 239, 247, .8);
//   box-shadow: 0 2px 4px 0 rgba(5, 47, 95, .04), 0 12px 16px 0 rgba(52, 105, 203, .12);
//   margin-bottom: 32px;
//   cursor: pointer;
//   transition: .3s;
//   position: relative;
// }
// .pb-product .product-desc {
//   font-size: 12px;
//   font-weight: 600;
//   color: #69829f;
//   margin-bottom: 12px;
//   width: 81%;
// }
// .pb-product.inactive .bestTag {
//   filter: grayscale(0);
// }
// .bestTag {
//   position: absolute;
//   bottom: 15px;
//   font-size: 12px;
//   color: #1b1dc7;
//   font-weight: 600;
// }
// .pb-product .product-icon {
//   text-align: right;
//   height: 60px;
//   margin-top: auto;
//   transition: all .3s;
//   position: absolute;
//   right: 5px;
//   top: 14px;
// }

.pb-product {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 160px;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(235, 239, 247, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
}

.product-name {
  font-size: 14px !important;
  color: #052f5f;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.product-color {
  font-size: 12px;
  color: #69829f;
}

.bestTag {
  position: absolute;
  bottom: 12px;
  left: 12px;
  font-size: 12px;
  color: #1b1dc7;
  font-weight: bold;
}

.created-date {
  font-size: 12px;
  color: #a1a1a1;
}

@media (max-width: 600px) {
  .custom-swal-size {
    width: 300px; /* Adjust the width for smaller screens */
  }
}

.cardSampleDashoard {
  border-radius: 12px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: #f9f9f9;
  border: none;
  margin-bottom: 20px;
  padding: 10px;
}

.cardSampleDashoard:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
}

.cardSampleDashoard .card-blockSample {
  padding: 1px;
}

.cardSampleDashoard h6 {
  font-size: 16px;
  color: #374151;
  font-weight: 500;
}

.cardSampleDashoard .text-right {
  font-size: 14px;
  color: #4a4a4a;
}

.cardSampleDashoard .d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardSampleDashoard .f300 {
  font-weight: 300;
}

.cardSampleDashoard .f-right {
  text-align: right;
}

.cardSampleDashoard .textGreyShade {
  color: #6c757d;
}

.cardSampleDashoard .cursor-pointer {
  cursor: pointer;
}

.cardSampleDashoard .col-lg-3,
.cardSampleDashoard .col-sm-12 {
  padding: 10px;
  text-align: center;
}

.cardSampleDashoard .col-lg-4 {
  padding: 10px;
  text-align: center;
}

.cardSampleDashoard .mt-1 {
  margin-top: 10px;
}

.cardSampleDashoard .mt-2 {
  margin-top: 20px;
}

.cardSampleDashoard .card-blockSample {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cardSampleDashoard .btn:focus {
  outline: none;
}

@media (max-width: 768px) {
  .cardSampleDashoard {
    margin-bottom: 20px;
  }

  .cardSampleDashoard .card-blockSample {
    padding: 12px;
  }

  .cardSampleDashoard .col-lg-3,
  .cardSampleDashoard .col-lg-4 {
    width: 100%;
    padding: 8px;
  }

  .cardSampleDashoard .f-right {
    text-align: left;
  }
}

@media (max-width: 576px) {
  .cardSampleDashoard h6 {
    font-size: 14px;
  }

  .cardSampleDashoard .col-lg-3,
  .cardSampleDashoard .col-lg-4 {
    width: 100%;
    text-align: center;
  }
}

/* CSS for custom card styling */
.custom-card {
  background-color: #dcfce7;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.card-content {
  gap: 1rem; /* Adjust gap as needed for spacing */
}

.image-container {
  flex: 1 1 100px; /* Adjust for responsive resizing */
}

.responsive-image {
  width: 20%;
  height: auto;
  border-radius: 8px;
}
/* Style the table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 13px;
}

.styled-table th {
  background-color: #f0f0f0;
  color: black;
}

.styled-table tr:nth-child(even) {
  background-color: #f4f4f4;
}

.styled-table tr:hover {
  background-color: #e9e9e9;
}

.styled-table td {
  background-color: white;
}

/* Adding scrollable container */
.styled-table-wrapper {
  max-height: 400px;
  overflow-y: auto;
}
.custom_margin_left {
  margin-left: 70px;
}
.accordion-button:focus {
  outline: 0 !important;
}
.light_black h6 {
  color: #3a3a3a;
}
.accordion-button {
  padding: 1px 23px !important;
}
.track_card {
  position: absolute !important;
  width: 30%;
  z-index: 1000;
  top: -10px;
  right: 4px;
  padding: 2px;
}
.farmer_modal .modal-content {
  background: #f5f7fa;
  padding: 0px;
}
.leaf {
  margin-left: "50px" !important;
}
.form-control {
  color: #313131 !important;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar .navbar-nav {
    background: transparent !important;
  }
}
/* mobile */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .leaf {
    margin-left: "20px" !important;
  }
  .mt5 {
    margin-top: 5px;
  }
  .track_card {
    position: relative !important;
    width: 100%;

    padding: 2px;
  }
  .container_tabs {
    background-color: transparent;

    .tabs_wrapper {
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1);
      background-color: #ecececba;
    }
    .tab {
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }
  }
  .farmer_map {
    height: 38vh;
  }
  .transparent_card {
    background: transparent;
    box-shadow: none;
  }
  .margin_top {
    margin-top: 1%;
  }
  .custom_mb {
    margin-bottom: 0px;
  }
  .custom_margin_left {
    margin-left: 25px;
  }
  .grayBorderRight {
    border: none;
  }
  .navbar .navbar-nav {
    background: transparent !important;
  }
  .right-border-shadow {
    position: relative;
    // background-color: white;
    padding: 7px;
  }
  .right-border-shadow::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 90%;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  .cardDesign {
    box-shadow: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  .globalboxshadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px;
    border-left: 2px light orange;
    border-right: 2px light orange;
  }
}
.dropdown-hover-item:hover {
  background-color: #bdbdbd; /* Adjust the color to your preference */
  color: white; /* Adjust text color on hover for better visibility */
}