@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

/********** Template CSS **********/
* {
  font-family: "Josefin Sans", serif !important;
}
:root {
  --primary: #ff6f0f;
  --secondary: #fff0e6;
  --light: #f8f8f9;
  --dark: #001d23;
  /* font-family: "Josefin Sans", serif !important; */
}
h1,
h2,
h3,
h5,
h6,
.h1,
.h2,
.h3,
.h5,
.h6 {
  font-family: "Josefin Sans", serif !important;
  font-weight: 700 !important;
}
h4 {
  font-family: "Josefin Sans", serif !important;
  font-weight: 600 !important;
}
.fs-6 {
  font-size: 1.03rem !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.dot {
  width: 9px;
  height: 9px;
  background: lightgray;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin: 0 8px !important;
  animation: none !important;
  -webkit-animation: none !important;
}

.slick-dots .slick-active .dot {
  width: 30px !important;
  height: 10px !important;
  background: #ff5722 !important;
  border-radius: 10px !important;
  margin: 0px -3px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 8px !important;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
  color: #ffffff;
}
.bg-orange {
  background-color: #ff6f0f !important;
}
.custom-hover-effect {
  transition: transform 0.2s ease-in-out, box-shadow 0.6s;
  border-radius: 12px;
}

.custom-hover-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.btn.btn-primary:hover {
  color: var(--primary);
  background: transparent;
}
.img-hover:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}
.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
  border-left: 2px solid #ff6f0f;
  border-right: 2px solid #ff6f0f;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}
/* navbar  */

/* .fixed-top {
  transition: top 0.5s ease-in-out;
} */

/* .navbar-hidden {
  top: -100px;
} */

.get-started {
  background: linear-gradient(to right, #ff6f0f, #ff8e0f);
  color: white;
  border: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.get-started:hover {
  background: linear-gradient(to right, #ff8e0f, #946308);
  color: white !important;
  box-shadow: 0px 8px 15px rgba(255, 111, 15, 0.4);
}

.top-bar {
  background-color: transparent;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.top-bar.visible {
  transform: translateY(0);
  opacity: 1;
}

.top-bar.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  /* font-family: "Font Awesome 5 Free"; */
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #ffffff;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav {
    /* margin-top: 10px; */
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    background: var(--dark);
  }

  .navbar .navbar-nav .nav-link {
    padding: 10px 0;
  }
  .display-3 {
    font-size: 2rem !important;
  }
  .display-2 {
    font-size: 2rem !important;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    transition: 0.5s;
    opacity: 0;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/

.carousel-item {
  height: 110dvh;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--dark);
  border: 12px solid var(--dark);
  border-radius: 3rem;
}
.hero_carousel {
  height: 100svh;
}
.qr_code_img {
  height: 200px;
}
.footer_logo {
  height: 80px;
  object-fit: contain;
}
.gradient_text {
  background-image: linear-gradient(60deg, #f55019, #ffb03a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-gradient::before {
  content: "";
  position: absolute;
  top: 10%;
  /* left: -20%;
  width: 80%;
  height: 70%;
  background: radial-gradient(
    circle,
    rgba(255, 149, 4, 0.2) 10%,
    rgba(255, 255, 255, 1) 90%
  ); */

  left: 3%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgb(255 180 62 / 19%) 30%,
    rgba(255, 255, 255, 1) 80%
  );
  /* background: linear-gradient(
    120deg,
    rgba(255, 147, 0, 0.3) 30%,
    rgba(255, 255, 255, 1) 80%
  ); */
  z-index: -1;
  pointer-events: none;
  filter: blur(80px);
  -webkit-filter: blur(50px);
}
.offering_carousel .slick-dots {
  margin: -10px 8px !important;
}
.mobile_image {
  height: 500px;
}
.region-img {
  height: 320px;
}
.custom_mobile_image {
  height: 510px;
}
.mobile_inside_image {
  width: 81%;
  height: 82%;
  object-fit: cover;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.credit_score_img {
  height: 200px;
}
/*tablet*/
@media only screen and (min-width: 768px) and(max-width: 1023px) {
  .hero_carousel {
    height: 80svh;
  }
  .qr_code_img {
    height: 200px;
  }
  .footer_logo {
    height: 80px;
    object-fit: contain;
  }
  .custom_mobile_image {
    height: 510px;
  }
}
/* mobile */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .custom_mobile_image {
    height: 340px;
  }
  .credit_score_img {
    height: 150px;
  }
  .mobile_inside_image {
    width: 85%;
    height: 82%;
    object-fit: cover;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .shadow-box {
    box-shadow: none;
    border: none;
  }
  .mobile_card {
    border: none;
    border-radius: 5px;
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
  }
  .qr_code_img {
    height: 110px;
  }
  .footer_logo {
    height: 90px;
    object-fit: contain;
  }
  .region-img {
    height: 180px;
  }
  .shadow-gradient::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -20%;
    width: 90%;
    height: 100%;
    /* background: radial-gradient(
      circle,
      rgba(255, 149, 4, 0.3) 10%,
      rgba(255, 255, 255, 0) 70%
    ); */
    background: linear-gradient(
      120deg,
      rgb(255 180 62 / 19%) 30%,
      rgba(255, 255, 255, 1) 80%
    );
    z-index: -1;
    pointer-events: none;
    filter: blur(80px);
    -webkit-filter: blur(80px);
  }
  .offering_carousel .slick-track {
    width: 3744px;
    opacity: 1;
    height: 350px;
    transform: translate3d(-416px, 0px, 0px);
  }
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  padding-top: 12rem;
  padding-bottom: 6rem;
  background: linear-gradient(rgba(0, 29, 35, 0.8), rgba(0, 29, 35, 0.8)),
    url(../../../../public/assets/img/field.jpg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: #999999;
}

/*** Features Start ***/
.feature .feature-item {
  text-align: center;
  border-radius: 10px;
  background: var(--light);
  border: 1px solid transparent;
  transition: 0.5s;
}

.feature .feature-item:hover {
  border: 1px solid var(--primary);
}

.feature .feature-item .feature-icon {
  background: var(--white);
  border-radius: 10px;
  display: inline-block;
}
/*** Features End ***/

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}

.section-title::before {
  position: absolute;
  content: "";
  width: calc(100% + 80px);
  height: 2px;
  top: 5px;
  left: -40px;
  background: var(--primary);
  z-index: -1;
}

.section-title::after {
  position: absolute;
  content: "";
  width: calc(100% + 120px);
  height: 2px;
  bottom: 6px;
  left: -60px;
  background: var(--primary);
  z-index: -1;
}

.section-title.text-start::before {
  width: calc(100% + 40px);
  left: 0;
}

.section-title.text-start::after {
  width: calc(100% + 60px);
  left: 0;
}

/*** About ***/
.about-experience {
  position: absolute;
  width: 100%;
  height: 100%;
  right: -45px;
  bottom: -45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*** Causes ***/
.causes-item .progress {
  height: 5px;
  border-radius: 0;
  overflow: visible;
}

.causes-item .progress .progress-bar {
  position: relative;
  overflow: visible;
  width: 0px;
  border-radius: 0;
  transition: 5s;
}

.causes-item .progress .progress-bar span {
  position: absolute;
  top: -7px;
  right: 0;
  width: 40px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: var(--primary);
  color: #ffffff;
}

.causes-item .causes-overlay {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
}

.causes-item:hover .causes-overlay {
  height: 100%;
  opacity: 1;
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.06);
}

/*** Donate ***/
.donate {
  background: rgba(0, 29, 35, 0.8);
}

.btn-group .btn-light:hover,
.btn-group input[type="radio"]:checked + label {
  color: var(--primary);
  border-color: var(--primary);
}

/*** Team ***/
.team-item img {
  position: relative;
  top: 0;
  transition: 0.5s;
}

.team-item:hover img {
  top: -30px;
}

.team-item .team-text {
  position: relative;
  height: 100px;
  transition: 0.5s;
}

.team-item:hover .team-text {
  margin-top: -60px;
  height: 160px;
}

.team-item .team-text .team-social {
  opacity: 0;
  transition: 0.5s;
}

.team-item:hover .team-text .team-social {
  opacity: 1;
}

.team-item .team-social .btn {
  display: inline-flex;
  color: var(--primary);
  background: #ffffff;
  border-radius: 40px;
}

.team-item .team-social .btn:hover {
  color: #ffffff;
  background: var(--primary);
}

/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 300px;
  }
}

.testimonial-carousel .owl-item .testimonial-text {
  background: var(--light);
  transform: scale(0.8);
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  background: var(--primary);
  transform: scale(1);
}

.testimonial-carousel .owl-item .testimonial-text *,
.testimonial-carousel .owl-item .testimonial-item img {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
  color: var(--light) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item img {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 350px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
  width: 300px;
  opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  color: var(--primary);
  font-size: 45px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

/*** Footer ***/

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  /* font-family: "Font Awesome 5 Free"; */
  font-weight: 900;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .btn.btn-square {
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.footer .btn.btn-square:hover {
  color: var(--secondary);
  border-color: var(--light);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--secondary);
}

.footer .copyright a:hover {
  color: var(--primary);
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-1%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes infinite-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bounce-effect {
  position: relative;
  animation: animated_bounce 3s ease-in-out infinite;
}

@keyframes animated_bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounce-effect2 {
  position: relative;
  animation: animated_bounce 3s ease-in-out infinite;
}

@keyframes animated_bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -o-transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}

.slidein-animation {
  animation: slidein 1s ease var(--slidein-delay, 0) forwards;
}

.infinite-scroll-animation {
  animation: infinite-scroll 25s linear infinite;
}

.hero-section .carousel-slide {
  position: relative;
}

.hero-section .carousel-caption h1 {
  font-size: 2rem;
}

.hero-section .carousel-caption p {
  font-size: 1.2rem;
}

.hero-section .slick-prev,
.hero-section .slick-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  display: flex !important;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: transform 0.2s ease;
}

.hero-section .slick-prev:hover,
.hero-section .slick-next:hover {
  transform: scale(1.1);
}

.hero-section .slick-prev i,
.hero-section .slick-next i {
  color: white;
  font-size: 20px;
}

.offwhite {
  color: #cecece;
}
